import React from 'react';
import './definitionList.scss'

export default class Header extends React.Component {
  render() {
    return (
      <dl className="definition-list">
        {this.props.children}
      </dl >
    )
  }
}
