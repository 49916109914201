import React from "react";
import Modal from "../Modal.jsx";

export default class EvertrheadModal extends React.Component {
  render() {
    return (
      <Modal title="Everthread" id="everthread" onClose={this.props.onClose}>
        <div className="iframe-container">
          <iframe
            src="https://youtube.com/embed/lnaU4lxJg2Y"
            frameBorder="0"
            allowFullScreen
            title="Klock Consulting"
          />
        </div>
        <p>
          For 1.5 years, I have been leading the core development of a product which
            facilitates eye catching visualizations for ecommerce vendors.
        </p>

        <p>
          Above video shows one of many examples created with this product.
        </p>

        <ul className="list-inline item-details">
          <li>
            Client:
            <strong>
              &nbsp;Everthread
            </strong>
          </li>
          <li>
            Date:
            <strong> 02/2018 - 7/2019 </strong>
          </li>
        </ul>
      </Modal>
    );
  }
}
