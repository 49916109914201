import React from "react";
import "./Separator.scss";

export default class Separator extends React.Component {
  render() {
    return (
      <div className={`separator star-light ${this.props.background}`}>
      </div>
    );
  }
}

