import React from "react";
import "./header.scss";
import { scrollTo } from "../../service/scroll";

export default () => {
  const learnMore = () => {
    scrollTo("#portfolio");
  };
  return (
    <section
      className={`header background`}
      id="video"
    >
      <div className="container">
        <iframe
          width="853"
          height="505"
          src="https://www.youtube.com/embed/Kt_leYE22W8"
          title="Klock Consulting"
          frameBorder="0"
          allowFullScreen
        />
        <div className="page-scroll">
          <button onClick={learnMore} className="flatButton">
            Learn More
          </button>
        </div>
      </div>
    </section>
  );
};
