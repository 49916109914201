import React from "react";
import Modal from "../Modal.jsx";

export default class ZenhomesModal extends React.Component {
  render() {
    return (
      <Modal
        title="Zenhomes"
        id="modalZenhomes"
        onClose={this.props.onClose}
      >
        <img
          src="/img/portfolio/zenhomes.jpg"
          className="img-responsive img-centered"
          alt="zenhomes"
        />
        <p>
          This was a 4 month project for a startup creating a property
          management website for landlords.
        </p>
        <ul className="list-inline item-details">
          <li>
            Client:
            <strong>
              <a href="https://www.vermietet.de/"> https://www.vermietet.de/</a>
            </strong>
          </li>
          <li>
            Date:
            <strong> 07/2016 - 11/2016 </strong>
          </li>
          <li>
            Service: <strong> Frontend Development (Angular) </strong>
          </li>
        </ul>
      </Modal>
    );
  }
}
