// @ts-ignore
var scrollIntoView = require("scroll-into-view");

export const scrollTo = (hash: string) => {
  const element = document.querySelector(hash);
  scrollIntoView(element, {
    time: 1000,
    align: {
      top: 0,
    },
  });
};
