import React from "react";
import DefinitionList from "../definitionList/definitionList.tsx";
import Separator from "../separator/Separator.jsx";
import "./Skills.scss";
import {aosLeft, aosRight} from "../const";

export default class Skills extends React.Component {
  render() {
    return (
      <section id="skills">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2>Skills</h2>
              <Separator background="white" />
            </div>
          </div>
          <div className="skills-content">
            <div className="col" data-aos={aosRight}>
              <DefinitionList>
                <dt>Javascript</dt>
                <dd>
                  Node.js, Typescript, ES7, React, Redux, Angular.js, Angular
                  7, RxJs, Karma, Jasime, Jest, Cypress, Lodash, Express,
                  Webpack, Meteor, ESLint, Prettier, JWT,
                  Websockets, Bootstrap, Express, JQuery, SVG, HTML 5
                  Canvas
                </dd>

                <dt>Java</dt>
                <dd>
                  Spring Boot, Java EE, Junit, Maven, Mockito, Hibernate, JSF,
                  SAX, XML, Dependency Injection
                </dd>

                <dt>Databases</dt>
                <dd>
                  PostgreSQL, MySQL, Oracle, MongoDB
                </dd>

                <dt>Others</dt>
                <dd>
                  Docker, Responsive Design, SASS, Nginex, Git,
                  Domain Driven Design, Continuous Integration,
                  Docker, Jenkins, Bash, Debian, Scrum, Cryptography
                </dd>
              </DefinitionList>
            </div>
            <div className="col hidden-mobile img" data-aos={aosLeft}>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
