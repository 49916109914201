import React from "react";
import "./About.scss";
import Separator from "../separator/Separator.jsx";
import { aosLeft, aosRight } from "../const";

export default class About extends React.Component {
  render() {
    return (
      <section id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2>About</h2>
              <Separator background="grey" />
            </div>
          </div>
          <div className="about-content">
            <div className="hidden-mobile" data-aos={aosRight}>
              {/*
              <video width="390" muted autoPlay loop controls="false">
                <source src="../static/img/stock.mp4" type="video/mp4" />
              </video>
             */}
              <img
                src="/img/glasses.jpeg"
                className="about-img"
                alt="Notebook"
              />
            </div>
            <div className="col">
              {/*
                Enterprise:
                IAV, LANCOM, 1 und 1
                2.5, 0.5, 0.5
                Thread etc. 1.5
                AQuire
                Everthread 1.5
                Codekunst 0.3
              */}
              <div className="">
                {/*
                KIT is ranked 20th place of all worldwide Universities in
                Category Employability, QS Ranking, 2017.
                */}{" "}
                <div>
                  <ul data-aos={aosLeft}>
                    <li>7 years of industry experience</li>
                    <li>German diploma (takes 9 semesters) in computer science</li>
                    <li>
                      External contractor in teams from different countries
                    </li>
                    <li>Specialized in complex frontends based on React.js</li>
                    <li>
                      Experience in all layers of web applications (Fullstack)
                    </li>
                    <li>
                      Personal network of reliable freelancers that can support
                    </li>
                  </ul>
                </div>
                <p>
                  {/*
                <span className="clean">Clean code</span> requires a combination of time intensive, thorough code reviews
                and high regression test coverage. Most importantly though it requires an
                ongoing collective effort to divide systems into small, simple and highly
                decoupled components.
                  */}
                </p>
              </div>

              <br />
              <div>
                <div className="button-outer">
                  <a download href="/cv.pdf">
                    <button className="button">Download CV as PDF</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
