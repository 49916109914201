import React from "react";
type Props = {
  src: string;
  type: string;
  srcAlt: string;
  alt: string;
  className?: string;
};
export const Img = ({ src, type, srcAlt, alt, className }: Props) => (
  <picture>
    <source srcSet={src} type={type} />
    <img src={srcAlt} alt={alt} className={className}/>
  </picture>
);
