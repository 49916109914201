import React, { useLayoutEffect, useRef } from "react";
import "./Navigation.scss";
import { scrollTo } from "../../service/scroll";

const FIX_NAVBAR_OFFSET = 100;

export default () => {
  const navRef = useRef<HTMLDivElement>();
  const isLastRenderScrolled = useRef(false);
  useLayoutEffect(() => {
    window.addEventListener("scroll", function (e) {
      if (window.scrollY > FIX_NAVBAR_OFFSET && !isLastRenderScrolled.current) {
        navRef.current!.classList.remove("isOnTop");
        navRef.current!.classList.add("isScrolledDown");
        isLastRenderScrolled.current = true;
      } else if (
        window.scrollY <= FIX_NAVBAR_OFFSET &&
        isLastRenderScrolled.current
      ) {
        navRef.current!.classList.add("isOnTop");
        navRef.current!.classList.remove("isScrolledDown");
        isLastRenderScrolled.current = false;
      }
    });
  }, []);

  const onMenuClick = () => {
    onNavItemClick("")(null);
  };

  const onNavItemClick = (hash: string) => (ev: any) => {
    const collapsible = document.getElementById("navbar-collapsable");
    collapsible!.classList.toggle("collapsed");
    if (hash) {
      if (hash) scrollTo(hash);
    }
    if (ev) ev.preventDefault();
  };

  return (
    <nav
      ref={(ref: HTMLDivElement) => (navRef.current = ref)}
      id="mainNav"
      className="navbar navbar-fixed-top navbar-custom isOnTop"
    >
      <div className="container">
        <div className="navbar-header page-scroll">
          <a className="navbar-brand" href="#page-top">
            Klock Consulting
          </a>
          <button type="button" className="navbar-toggle" onClick={onMenuClick}>
            <span className="sr-only"> </span>
            Menu <i className="fa fa-bars" />
          </button>
        </div>

        <div id="navbar-collapsable" className="collapsed">
          <ul className="nav navbar-nav">
            <li className="page-scroll" id="videoNav">
              <a onClick={onNavItemClick("#video")} href="#video">
                Video
              </a>
            </li>
            <li className="page-scroll" id="portfolioNav">
              <a onClick={onNavItemClick("#portfolio")} href="#portfolio">
                Portfolio
              </a>
            </li>
            <li className="page-scroll" id="aboutNav">
              <a onClick={onNavItemClick("#about")} href="#about">
                About
              </a>
            </li>
            <li className="page-scroll" id="skillsNav">
              <a onClick={onNavItemClick("#skills")} href="#skills">
                Skills
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
