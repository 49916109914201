import React from "react";
import Modal from "../Modal.jsx";

export default class DffModal extends React.Component {
  render() {
    return (
      <Modal title="Dubai Future Foundation" id="dff" onClose={this.props.onClose}>
          <img
              src="/img/portfolio/dff.gif"
              className="img-responsive img-centered"
              alt=""
          />
        <p>
            As part of a Team creating web applications for the Government of the United Arab Emirates
            I worked on multiple websites  in 2019/2020. For example I was involved in a platform to streamline
            and automate the process of business visa applications for applicants and reviewers. In another project
            we created a single sign on platform for managing identities throughout several Government websites.
        </p>

        <ul className="list-inline item-details">
          <li>
            Client:
            <strong>
              &nbsp;Motius
            </strong>
          </li>
          <li>
            Date:
            <strong> 2019-2020 </strong>
          </li>
        </ul>
      </Modal>
    );
  }
}
