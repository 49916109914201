import React, { useEffect } from "react";
import "./modal.scss";

export default (props) => {
  useEffect(() => {
    window.document.body.style.overflow = "hidden";
    return () => {
      window.document.body.style.overflow = "auto";
    };
  });

  return (
    <div
      className={`portfolio-modal modal fade `}
      id={props.id}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-content">
        <div
          className="close-modal"
          onClick={() => {
            props.onClose(null);
          }}
        >
          <div className="lr">
            <div className="rl" />
          </div>
        </div>
        <div className="container">
          <div className="modal-body">
            <h2>{props.title}</h2>

            {/*<hr className="star-primary" />*/}

            {props.children}

            <button
              type="button"
              className="btn btn-default"
              onClick={() => {
                props.onClose(null);
              }}
            >
              <i className="fa fa-times" /> Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
