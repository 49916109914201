import React, { ReactElement } from "react";
import "./PortFolioPreviewItem.scss";

type Props = {
  modalId: string;
  previewAltText: string;
  previewImage: string;
  previewImageAlt?: ReactElement;
  setActiveModal: (modalId: string) => void;
};

export default (props: Props) => {
  const { modalId } = props;

  const setActiveModal = () => {
    props.setActiveModal(modalId);
    return false;
  };

  return (
    <div className="portfolio-item" data-aos="zoom-in-up">
      <div className="portfolio-link" onClick={setActiveModal}>
        <div className="caption">
          <div className="caption-content">
            <i className="fa fa-search-plus fa-3x"></i>
          </div>
        </div>
        {props.previewImageAlt ? (
          props.previewImageAlt
        ) : (
          <img
            src={
              "/img/portfolio/" + (props.previewImageAlt || props.previewImage)
            }
            className="img-responsive"
            alt={props.previewAltText}
          />
        )}
      </div>
    </div>
  );
};
