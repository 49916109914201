import React, { useLayoutEffect, useMemo, useState } from "react";
import "./App.scss";
import Navigation from "./Navigation/Navigation";
import Header from "./header/Header";
import Portfolio from "./Portfolio/Portfolio";
import "../scss/freelancer.scss";
import About from "./about/About";
import Skills from "./skills/Skills";
import Footer from "./footer/Footer";
import { highlightActiveNavElement } from "../service/findActiveSection";
import PmoModal from "./Modal/modals/PmoModal";
import EverthreadModal from "./Modal/modals/EverthreadModal";
import ZenhomesModal from "./Modal/modals/ZenhomesModal";
import DffModal from "./Modal/modals/DffModal";
import LancomModal from "./Modal/modals/LancomModal";
import EueModal from "./Modal/modals/EueModal";

function App() {
  useLayoutEffect(() => {
    window.addEventListener("scroll", highlightActiveNavElement);
    highlightActiveNavElement();
  }, []);
  const [activeModal, setActiveModal] = useState<string>("");

  const modals = useMemo(
    () => [
      { id: "pmo", component: PmoModal },
      { id: "everthread", component: EverthreadModal },
      { id: "lancom", component: LancomModal },
      { id: "dff", component: DffModal },
      { id: "modalZenhomes", component: ZenhomesModal },
      { id: "eue", component: EueModal },
    ],
    []
  );

  return (
    <div>
      <Navigation />
      <Header />
      <Portfolio setActiveModal={setActiveModal} />
      <About />
      <Skills />
      <Footer />
      {modals.map((modal) => {
        if (`#${modal.id}` === activeModal) {
          return React.createElement(modal.component, {
            onClose: setActiveModal,
          });
        }
        return null;
      })}
    </div>
  );
}

export default App;
