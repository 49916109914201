import React from "react";
import PortfolioPreviewItem from "../PortfolioPreviewItem/PortfolioPreviewItem";
import Separator from "../separator/Separator.jsx";
import "./Portfolio.scss";
import { EueImage } from "../Img/EueImage";

export default class Portfolio extends React.Component<any, any> {
  render() {
    return (
      <section id="portfolio">
        <div className="col-lg-12 text-center">
          <h2>Portfolio</h2>
          <Separator background="white"></Separator>
        </div>
        <div className="portfolio-items">
          <PortfolioPreviewItem
            modalId={"#dff"}
            previewImage={"dff.gif"}
            previewAltText={"Dubai Future Foundation"}
            setActiveModal={this.props.setActiveModal}
          />
          <PortfolioPreviewItem
            modalId={"#pmo"}
            previewImage={"pmo.png"}
            previewAltText={"Prime Minister Office Dashboard"}
            setActiveModal={this.props.setActiveModal}
          />
          <PortfolioPreviewItem
            modalId={"#lancom"}
            previewImage={"cloud.lancom.s.png"}
            previewAltText={"Lancom"}
            setActiveModal={this.props.setActiveModal}
          />
          <PortfolioPreviewItem
            modalId={"#eue"}
            previewImage={"mobile.1und1.de.s.webp"}
            previewImageAlt={<EueImage />}
            previewAltText={"1&1"}
            setActiveModal={this.props.setActiveModal}
          />
          <PortfolioPreviewItem
            modalId={"#everthread"}
            previewImage={"couch.gif"}
            previewAltText={"Everthread"}
            setActiveModal={this.props.setActiveModal}
          />
          <PortfolioPreviewItem
            modalId={"#modalZenhomes"}
            previewImage={"zenhomes.jpg"}
            previewAltText={"Zenhomes"}
            setActiveModal={this.props.setActiveModal}
          />
        </div>
      </section>
    );
  }
}
