import React from "react";
import { Img } from "./Img";

type Props = {
  className?: string
}
export const EueImage = (props: Props) => (
  <Img
    className={props.className}
    src="/img/portfolio/mobile.1und1.de.s.webp"
    srcAlt={"/img/portfolio/mobile.1und1.de.s.jpg"}
    type="image/webp"
    alt="1und1"
  />
);
