import React from "react";
import Modal from "../Modal.jsx";
import {EueImage} from "../../Img/EueImage";

export default class EueModal extends React.Component {
  render() {
    return (
      <Modal title="1&1 Mobile" id="eue" onClose={this.props.onClose}>
        <EueImage className="img-responsive img-centered"/>
        <p>
          In 2018 I worked as onsite consultant and developer at 1&1 Internet AG.
          I was part of an agile team running the mobile shop of the company:&nbsp;
          <a href="https://mobile.1und1.de/">https://mobile.1und1.de/</a>
        </p>

        <ul className="list-inline item-details">
          <li>
            Client:
            <strong>
              <a href="http://www.1und1.de">&nbsp;1&1</a>{" "}
            </strong>
          </li>
          <li>
            Date:
            <strong> 09/2018 - 01/2019 </strong>
          </li>
        </ul>
      </Modal>
    );
  }
}
