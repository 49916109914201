function getDocHeight() {
  var D = document;
  return Math.max(
    D.body.scrollHeight,
    D.documentElement.scrollHeight,
    D.body.offsetHeight,
    D.documentElement.offsetHeight,
    D.body.clientHeight,
    D.documentElement.clientHeight
  );
}

const sections = ["video", "portfolio", "about", "skills"];

let lastActive: any;
const findActiveSection = () => {
  const scrollTop  = window.pageYOffset || document.documentElement.scrollTop;
  if (scrollTop + window.innerHeight >= getDocHeight()) return sections[sections.length - 1];

  const currentSection = sections
    .slice()
    .reverse()
    .find((section) => {
      // @ts-ignore
      const sectionTop = document.getElementById(section).offsetTop;
      return scrollTop >= (sectionTop - 300);
    });
  return currentSection
};

export const highlightActiveNavElement = () => {
  const currentSection = findActiveSection()
  if (lastActive) {
    lastActive.classList.remove("active");
  }
  lastActive = document.getElementById(currentSection + "Nav");
  // @ts-ignore
  lastActive.classList.add("active");
}
