import React from "react";
import Modal from "../Modal.jsx";

export default class LancomModal extends React.Component {
  render() {
    return (
      <Modal title="Lancom" id="lancom" onClose={this.props.onClose}>
        <div className="iframe-container">
          <iframe
            height="360"
            src="https://www.youtube.com/embed/PKZvW_pPp10"
            frameBorder="0"
            title="Klock Consulting"
            allowFullScreen
          />
        </div>
        <p>
          February to June 2019 I worked as remote consultant and developer on
          the project "Management Cloud" from{" "}
          <a href="https://www.lancom.de/">LANCOM</a>
        </p>

        <ul className="list-inline item-details">
          <li>
            Client:
            <strong>
              <a href="http://www.lancom.de">&nbsp;LANCOM</a>{" "}
            </strong>
          </li>
          <li>
            Date:
            <strong> 02/2019 - 06/2019 </strong>
          </li>
        </ul>
      </Modal>
    );
  }
}
