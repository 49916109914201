import React from 'react';
import "./Footer.scss";

export default class Footer extends React.Component {
  render() {
    return (
      <footer id="footer" className="text-center" >
        <div className="footer-above" >
          <div className="container" >
            <div className="row" >
              <div className="footer-col" >
                <h3 >LinkedIn:</h3 >
                <h4><a href="https://www.linkedin.com/in/tobiasklock">Tobias Klock</a></h4>
              </div >
              <div className="footer-col" >
                <h3 >E-mail:</h3 >
                <h4 ><a href="mailto:TobiasKlock84@gmail.com">TobiasKlock84@gmail.com</a></h4>
              </div >
            </div >
          </div >
        </div >
        <div id='footer' className="footer-below" >
          <div className="container" >
            <div className="row" >
              <div className="col-lg-12" >
                Copyright &copy; Klock Consulting 2017
              </div >
            </div >
          </div >
        </div >
      </footer >
   )
  }
}
