import React from "react";
import Modal from "../Modal.jsx";

export default class PmoDashboard extends React.Component {
  render() {
    return (
      <Modal title="Prime Minister Office Dashboard" id="pmo" onClose={this.props.onClose}>
          <img
              src="/img/portfolio/pmo.png"
              className="img-responsive img-centered"
              alt=""
          />
        <p>
            One of the projects for the United Arab Emirates in 2020 was a tool called PMO - Dashboard.
            It facilitates keeping track progress, spending and other information of Government Projects.

        </p>

        <ul className="list-inline item-details">
          <li>
            Client:
            <strong>
              &nbsp;Motius
            </strong>
          </li>
          <li>
            Date:
            <strong> 2020 </strong>
          </li>
        </ul>
      </Modal>
    );
  }
}
